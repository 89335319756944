import React from 'react'
import PageLayout from 'src/components/PageLayout'
import Seo from 'src/components/Seo'
import { Typography } from '@local/shared/components/System'
import Box from '@local/shared/components/System/Box'
import Section from 'src/components/Section'
import { Hem } from 'src/components/Em'
import SavvasProfilePic from 'src/components/SavvasProfilePic'
import NetlifyForm from 'src/components/NetlifyForm'
import InputGroup from 'src/components/InputGroup'
import { MainCta } from 'src/components/Button'

const SurveyLP = ({ location }) => {
  return (
    <PageLayout type={'condensed'}>
      <Seo
        title="WildLife Weight Loss Coaching"
        description={
          'Lose weight, get fit and become a superhuman using tried-and-tested techniques used by our ancestors.'
        }
        shortTeaser={
          'Lose weight, get fit and become a superhuman using tried-and-tested techniques used by our ancestors.'
        }
        locationPathname={location.pathname}
      />
      <Section trackingName={'title'}>
        <Typography variant={'h1'} mb={'1em'}>
          Can’t lose weight?
        </Typography>
        <Typography variant={'h5'} as={'p'} mb={'0.5em'}>
          Hi there!
        </Typography>
        <Typography variant={'h5'} as={'p'} mb={'0.5em'}>
          My name is Savvas and I am on a mission to help people live better
          lives.
        </Typography>
        <Typography variant={'h5'} as={'p'} mb={'0.5em'}>
          I am looking for 5 people (I have space for just{' '}
          <Hem>3 more this month</Hem>), men or women who live anywhere in the
          UK and are <Hem>looking to lose weight</Hem>.
        </Typography>
        <Typography variant={'h5'} as={'p'} mb={'0.5em'}>
          I specialise in helping you finally get the body (and the life) you
          want if...
        </Typography>
        <ul>
          <Typography variant={'h5'} as={'li'} mb={'0.5em'}>
            You need to lose <Hem>more than 5 kg</Hem>
          </Typography>
          <Typography variant={'h5'} as={'li'} mb={'0.5em'}>
            You have been at your unhappy weight for a while (or forever)
          </Typography>
          <Typography variant={'h5'} as={'li'} mb={'0.5em'}>
            You have tried everything over the years and{' '}
            <Hem>literally nothing seems to work</Hem>
          </Typography>
        </ul>
        <Typography variant={'h5'} as={'p'} mb={'0.5em'}>
          Is this you? <Hem>I guarantee that I’ll be able to help you.</Hem>
        </Typography>
        <Typography variant={'h5'} as={'p'} mb={'2em'}>
          Please fill out the form below and I’ll get back to you ASAP!
        </Typography>
        <Typography variant={'h5'} as={'p'} mb={'0.5em'}>
          Thank you!
        </Typography>
        <Typography variant={'h5'} as={'p'} mb={'1em'}>
          Savvas Nicholas
        </Typography>
        <Box width={200} maxWidth={'60%'}>
          <SavvasProfilePic />
        </Box>
      </Section>
      <Section trackingName={'form'}>
        <Typography variant={'h4'} as={'p'} mb={'1em'}>
          Are you ready to finally lose weight?
        </Typography>
        <NetlifyForm name={'survey-lead-capture'} action={'/thank-you/survey'}>
          <InputGroup
            id={'full-name'}
            name={'full-name'}
            labelText={'Full Name'}
            type={'text'}
            placeholder={'Full Name'}
          />
          <InputGroup
            id={'email'}
            name={'email'}
            labelText={'Email'}
            type={'email'}
            placeholder={'Email'}
          />
          <MainCta
            type={'submit'}
            mt={'1em'}
            trackingName={'submit'}
            mainContent={'Submit'}
          />
        </NetlifyForm>
        <Typography variant={'note'} mt={'1em'}>
          I’ll email to find a time that suits you.
        </Typography>
      </Section>
    </PageLayout>
  )
}

export default SurveyLP
